@font-face {
  font-family: 'SF Pro Text Medium';
  src: url('fonts/SFPRODISPLAYBOLD.OTF') format('truetype');
  font-weight: 500;
  font-style: normal;
}
.header-container {
    text-align: center;
    margin: 0 10px;
  }
  
  .header-title {
    font-family: 'SF Pro Text Medium', sans-serif;
    font-size: 44px;
    font-weight: 700;
    line-height: 30px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .header-subtitle {
    font-family: 'SF Pro Text Medium', sans-serif;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 28px;
    color: #ffffff9c;
    text-align: center;
  }
  
  /* Mobil uyumluluk medya sorguları */
  @media (max-width: 768px) {
    .header-title {
      font-family: 'SF Pro Text Medium', sans-serif;
      font-size: 36px;
      line-height: 48px;
    }
  
    .header-subtitle {
      font-family: 'SF Pro Text Medium', sans-serif;
      font-size: 18px;
      line-height: 22px;
    }
  }
  
  @media (max-width: 480px) {
    .header-container{
      padding: 10px;
    }
    .header-title {
      font-family: 'SF Pro Text Medium', sans-serif;
      font-size: 40px;
      line-height: 36px;
      
    }
  
    .greeting-emoji {
      order: 2;
      margin-left: 8px;
      
    }
  
    .header-subtitle {
      font-family: 'SF Pro Text Medium', sans-serif;
      font-size: 32px;
      line-height: 28px;
    }
  }
  