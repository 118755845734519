.testOne {
  width: 1000px; /* Dialog genişliği */
  height: 550px; /* Yüksekliği artırıldı */
  background: #1e1e1e; /* Daha koyu opak arka plan */
  padding: 60px;
  box-sizing: border-box;
  position: relative;
  border-radius: 50px; /* Yuvarlatılmış köşeler */
  overflow: hidden;
  color: #ffffff;
  background: url('../requesthistory/bg.png') no-repeat center center;
  max-height: 650px; /* Sabit bir maksimum yükseklik */
  overflow-y: auto; /* Dikey kaydırma etkinleştir */
}

/* Scrollbar'ı tamamen gizle (tüm tarayıcılarda) */
.testOne {
  scrollbar-width: none; /* Firefox için scrollbar gizle */
}

.testOne::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari) için scrollbar gizle */
}

.RequestHistoryheader {
  display: flex;
  justify-content: space-between;
  font-weight: 700; /* Font ağırlığı azaltıldı */
  font-size: 32px; /* Biraz küçültüldü */
  line-height: 36px;
  font-family: 'SF Pro Text Medium', sans-serif;
}

.requestHistoryCloseIcon {
  position: fixed; /* Sabit pozisyon */
  right: 20px; /* Sağdan boşluk */
  top: 20px; /* Yukarıdan boşluk */
  z-index: 1000; /* Her şeyin üzerinde görünsün */
}

.card {
  margin-top: 30px;
  position: relative;
  width: 90%;
  border-radius: 16px;
  background: #3B3B3B;
  padding: 40px; /* Kartların yüksekliği artırıldı */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.date-section, .title-section, .status-section {
  text-align: left; /* Soldan başlama */
}

.date-section {
  flex: 0.3; /* Genişlik azaltıldı */
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #A9A9A9;
  max-width: 100px;
}

.title-section {
  flex: 1;
  display: flex;
  justify-content: flex-start; /* Soldan başlama */
  color: #A9A9A9;
  margin-left: 20px;
}

.status-section {
  flex: 1.5;
  display: flex;
  justify-content: flex-start; /* Soldan başlama */
  margin-left: 20px;
}

.status-icon {
  margin-left: 20px;
  border-radius: 10px;
}

.date, .title, .status {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-family: 'SF Pro Text Medium', sans-serif;
}

.time {
  margin-top: 5px;
}

.line-section {
  flex-shrink: 0;
  width: 10px;
}

.line {
  flex-shrink: 0;
  width: 1;
  height: 50px;
  margin: 0 15px;
}

.navigate-wheel-button {
  background: linear-gradient(135deg, #28D17C, #20c965); /* Gradient yeşil */
  color: white;
  border: none;
  border-radius: 20px; /* Daha yumuşak, oval köşeler */
  padding: 8px 16px;
  font-size: 0.9em;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Hafif bir 3D gölge */
  margin-left: 25px;
}

/* Hover efekti */
.navigate-wheel-button:hover {
  background: linear-gradient(135deg, #26b370, #1fa760); /* Daha koyu gradient */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25); /* Gölgeyi biraz artır */
  transform: translateY(-2px); /* Butonun hafifçe yukarı çıkması */
}

/* Aktif efekti (butona basıldığında) */
.navigate-wheel-button:active {
  background: #1a9e54; /* Solid, koyu yeşil */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transform: translateY(1px); /* Hafifçe aşağı hareket */
}


.lineLeft {
  background-image: url('../requesthistory/line.png');
}

.lineRight {
  background-image: url('../requesthistory/line.png');
}

.status {
  font-weight: 700;
}

.status-icon img {
  width: 35px;
  height: 35px;
}

.empty-state {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
}

.empty-icon {
  width: 70px; /* Boş durumu göstermek için ikonun boyutu */
  margin-bottom: 5px;
}

.empty-state p {
  font-size: 24px;
  color: #5B5B5B;
  font-weight: 500;
}

.empty-subtext {
  font-size: 24px;
  color: #5B5B5B;
  font-weight: 500;
}

@media (max-width: 830px) {
  .testOne {
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
    max-height: 450px; /* Sabit bir maksimum yükseklik ekliyoruz */
    overflow-y: auto; /* İçerik fazla olduğunda dikey kaydırmayı etkinleştiriyoruz */
  }

  .RequestHistoryheader {
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .line-section {
    display: none;
  }

  .card {
    padding: 20px;
    margin-top: 15px;
    border-radius: 10px;
    background: #3b3b3b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    gap: 10px;
  }

  .date, .title, .status {
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }

  .status-icon {
    margin-top: 10px;
  }

  .status-icon img {
    width: 28px;
    height: 28px;
  }

  .empty-state {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
  }

  .empty-icon {
    width: 60px;
    margin-bottom: 10px;
  }

  .empty-state p {
    font-size: 20px;
    color: #b0b0b0;
    font-weight: 500;
  }

  .empty-subtext {
    font-size: 18px;
    color: #b0b0b0;
    font-weight: 500;
  }
}
@media (max-width: 480px) {
  .testOne {
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
    max-height: 450px; /* Sabit bir maksimum yükseklik ekliyoruz */
    overflow-y: auto; /* İçerik fazla olduğunda dikey kaydırmayı etkinleştiriyoruz */
  }

  .RequestHistoryheader {
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .line-section {
    display: none;
  }

  .card {
    padding: 20px;
    margin-top: 15px;
    border-radius: 10px;
    background: #3b3b3b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    gap: 10px;
  }

  .date, .title, .status {
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }

  .status-icon {
    margin-top: 10px;
  }

  .status-icon img {
    width: 28px;
    height: 28px;
  }

  .empty-state {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
  }

  .empty-icon {
    width: 60px;
    margin-bottom: 10px;
  }

  .empty-state p {
    font-size: 20px;
    color: #b0b0b0;
    font-weight: 500;
  }

  .empty-subtext {
    font-size: 18px;
    color: #b0b0b0;
    font-weight: 500;
  }
  
}

@media (max-width: 450px) {

  .testOne {

    max-height: 400px;

  }
  .date, .title, .status {
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }
  .navigate-wheel-button {
    font-size: 0.75em; /* Mobil için daha küçük font */
    padding: 5px 10px; /* Daha dar padding */
    margin-left: 10px; /* Sol boşluğu daha da azalt */
    border-radius: 12px; /* Daha küçük köşe yuvarlama */
    gap: 5px; /* Simge ve metin arasındaki boşluk azalsın */
  }
}
@media (max-width: 429px) {

  .testOne {

    max-height: 400px;

  }
  .date, .title, .status {
    font-size: 13px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 400px) {
  .testOne {

    max-height: 350px;

  }


  .date, .title, .status {
    font-size: 11.4px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }
}

@media (max-width: 370px) {
  .date, .title, .status {
    font-size: 10.5px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }

  .navigate-wheel-button {
    font-size: 0.6em; /* Mobil için daha küçük font */
    padding: 2px 5px; /* Daha dar padding */
    margin-left: 10px; /* Sol boşluğu daha da azalt */
    border-radius: 10px; /* Daha küçük köşe yuvarlama */
    gap: 3px; /* Simge ve metin arasındaki boşluk azalsın */
  }

  
}

@media (max-width: 350px) {
  .date, .title, .status {
    font-size: 9px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }

  
}

@media (max-width: 325px) {
  .date, .title, .status {
    font-size: 7px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
  }

  
}