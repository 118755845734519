.tabs-container {
    display: flex;
    gap: 30px;
    padding: 12px 24px;
    background-color: #1d1d1d;
    border-radius: 8px;
    max-width: 500px;
    justify-content: center;
  }
  
  @media (max-width: 768px) {
    .tabs-container {
      gap: 10px;
      padding: 6px 12px;
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .tabs-container {
      gap: 0px;
      padding: 16px 32px;
      max-width: 100%;
    }
  }
  