
.tab-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;
    width: 200px;
    height: 50px;
    box-sizing: border-box;
    color: #e1e1e1;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    font-family: 'SF Pro Text Medium', sans-serif;
    
  }
  
  .tab-item.active {
    color: #e5b645;
    border-bottom: 2px solid #e5b645;
    padding-bottom: 8px;
    font-weight: 700;
  }
  
  @media (max-width: 768px) {
    .tab-item {
      font-size: 18px;
      padding: 10px 12px;
      width: 160px;
      height: 40px;
      font-family: 'SF Pro Text Medium', sans-serif;
    }
  }
  
  @media (max-width: 480px) {
    .tab-item {
      font-size: 18px;
      font-weight: 700;
      padding: 8px 8px;
      width: auto;
      height: 38px;
      font-family: 'SF Pro Text Medium', sans-serif;
    }
  }
  
  @media (max-width: 400px) {
    .tab-item {
      font-size: 15.5px;
      font-weight: 700;
      padding: 8px 8px;
      width: auto;
      height: 38px;
      font-family: 'SF Pro Text Medium', sans-serif;
    }
  }

  @media (max-width: 370px) {
    .tab-item {
      font-size: 14.5px;
      font-weight: 700;
      padding: 8px 8px;
      width: auto;
      height: 38px;
      font-family: 'SF Pro Text Medium', sans-serif;
    }
  }

  @media (max-width: 350px) {
    .tab-item {
      font-size: 13.5px;
      font-weight: 700;
      padding: 8px 8px;
      width: auto;
      height: 38px;
      font-family: 'SF Pro Text Medium', sans-serif;
    }
  }