.maintenance-mode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    color: #e5b645;
    padding: 20px;
  }
  
  .maintenance-mode h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .maintenance-mode p {
    font-size: 1.2em;
    color: #ccc;
  }
  