/* Ana container */
.bonus-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    justify-content: center;
    margin-top: 40px;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Kart stilleri */
  .bonus-card {
    position: relative;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
  }
  
  .bonus-image {
    width: 100%;
    border-radius: 12px;
    transition: filter 0.2s ease;
  }
  
  .bonus-image.hovered {
    filter: blur(4px);
  }
  
  /* Info icon */
  .info-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 35px;
    height: 35px;
    z-index: 2;
  }
  
  /* Butonlar grubu */
  .button-group {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 2;
  }
  
  .btn {
    border: none;
    border-radius: 10px;
    padding: 18px 32px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    font-family: 'SF Pro Text Medium', sans-serif;
  }
  
  .talep-btn {
    background-color: #e5b645;
    color: #1d1d1d;
  }
  
  .kurallar-btn {
    background-color: #1d1d1d;
    color: #fff;
  }
  
  /* Modal overlay */
  .modal-overlay-doublebonus {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    backdrop-filter: blur(7px);
  }
  
  /* Modal içerik */
  .modal-content-doublebonus {
    border-radius: 12px;
    position: relative;
    width: 1000px;
    max-width: 100%;
  }
  
  /* Mobil görünüm için medya sorgusu */
  @media (max-width: 768px) {
    .bonus-cards-container {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      gap: 15px;
      padding: 0px;
      margin-top: 0px;
      
    }
  
    .info-icon {
      width: 30px;
      height: 30px;
    }
  
    .btn {
      padding: 14px 26px;
      font-size: 14px;
      width: 110px;
    }
    
    .modal-content-doublebonus {
        width: 90%;
      }
      
  }
  

  
  @media (max-width: 480px) {
    .modal-content-doublebonus {
      width: 100%;
    }

    .info-icon {
      width: 25px;
      height: 25px;
    }
  }
  